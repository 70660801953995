<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 用户奖金记录
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="container" v-loading="loading">
      <el-table
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          size="small"
          height="500"
      >
        <el-table-column prop="Title" label="项目社区"></el-table-column>
        <el-table-column prop="TitleName" label="活动昵称"></el-table-column>
        <el-table-column prop="Name" label="姓名"></el-table-column>
        <el-table-column prop="Mobile" label="手机号码"></el-table-column>
        <el-table-column prop="ToNumber" label="贡献值">
          <template #default="scope">
            {{scope.row.ToNumber}}QB
          </template>
        </el-table-column>
        <el-table-column prop="Start" label="状态说明"></el-table-column>
        <el-table-column prop="PayTool" label="支付方式"></el-table-column>
        <el-table-column prop="Time" label="时间"></el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="query.pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {BonusFetchData} from '../../api/LogAdmin/index'


export default {
  data() {
    return {
      loading: true,
      query: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      tableData: [],
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
    // 分页导航
    handlePageChange(val) {
      this.loading = true
      this.query.pageIndex = val
      this.getData();
    },
    getData() {
      this.loading=true
      BonusFetchData({
        skip: this.query.pageIndex
      }).then(res => {
        this.loading=false
        let bindata = res.sum;
        let number = res.number
        this.query.pageTotal = number
        this.tableData = bindata;
      });
    }
  },
  components: {}
}


</script>

<style scoped lang="scss">
.handle-box {
  margin-bottom: 20px;
}

.table {
  width: 100%;
  font-size: 14px;
}

.green {
  color: #00a854;
}

.red {
  color: red;
}
</style>
